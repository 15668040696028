import React, { useRef } from "react";
import { useState } from "react";
import { Link as LinkScroll } from "react-scroll";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import logo from "../images/logos/logo.png";

const navigation = [
  { name: "About Us", href: "about" },
  { name: "Pricing", href: "services" },
  { name: "Testimonials", href: "testimonials" },
  { name: "Youtube", href: "youtube" },
  { name: "FAQs", href: "faqs" },
];

const NavBar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <Link
              to="/"
              className="-m-1.5 p-1.5"
            >
              <span className="sr-only">SM Driving</span>
              <img
                src={logo}
                className="h-20 w-auto"
                alt=""
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon
                className="h-6 w-6"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <LinkScroll
              to="about"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              About Us
            </LinkScroll>
            <LinkScroll
              to="services"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
            >
              Pricing
            </LinkScroll>
            <LinkScroll
              to="testimonials"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Testimonials
            </LinkScroll>
            <LinkScroll
              to="youtube"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              YouTube
            </LinkScroll>
            <LinkScroll
              to="faqs"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              FAQs
            </LinkScroll>
            <LinkScroll
              to="contact"
              className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer hover:underline hover:uppercase"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
            >
              Contact Us
            </LinkScroll>
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <Link
              to="/"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link
                to="/"
                className="-m-1.5 p-1.5"
              >
                <span className="sr-only">Your Company</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <LinkScroll
                    to="about"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    About Us
                  </LinkScroll>
                  <LinkScroll
                    to="services"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Pricing
                  </LinkScroll>
                  <LinkScroll
                    to="testimonials"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Testimonials
                  </LinkScroll>
                  <LinkScroll
                    to="youtube"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    YouTube
                  </LinkScroll>
                  <LinkScroll
                    to="faqs"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    FAQs
                  </LinkScroll>
                  <LinkScroll
                    to="contact"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    spy={true}
                    smooth={true}
                    offset={50}
                    duration={500}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    Contact Us
                  </LinkScroll>

                  {/* {navigation.map((item) => (
                    <Link
                      // key={item.name}
                      to={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))} */}
                </div>
                <div className="py-6">
                  <Link
                    to="/"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default NavBar;
