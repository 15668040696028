import React from "react";

const faqs = [
  {
    id: 1,
    question:
      "How many lessons will I need to take before I'm ready to take my driving test?",
    answer:
      "The number of lessons you will need will depend on your individual learning style and driving experience. I recommend booking an assessment lesson with me to get a better idea of how many lessons you may need.",
  },
  // More questions...
  {
    id: 2,
    question: "What type of cars do you use for lessons?",
    answer:
      "I use a Toyota Aqua Hybrid, which is easy to drive and equipped with all of the latest safety features.",
  },
  // More questions...
  {
    id: 3,
    question: " What happens if I fail my driving test?",
    answer:
      "If you fail your driving test, don't worry! I will work with you to identify any areas that need improvement and help you to develop a plan for future lessons. I'm here to support you throughout your driving journey and will do everything I can to help you succeed.",
  },
  // More questions...
  {
    id: 4,
    question: "Do you offer automatic or manual driving lessons?",
    answer:
      "I offer both automatic and manual driving lessons, so you can choose the option that best suits your needs and preferences.  You will have to have your own manual car if you wish to do manual driving lessons",
  },
  // More questions...
];

const Faqs = () => {
  return (
    <div
      className="bg-white"
      id="faqs"
    >
      <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
        <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          Frequently asked questions
        </h2>
        <p className="mt-6 max-w-2xl text-base leading-7 text-gray-600">
          Have a different question and can’t find the answer you’re looking
          for? Reach to us now by calling or{" "}
          <a
            href="#"
            className="font-semibold text-indigo-600 hover:text-indigo-500"
          >
            sending us an email
          </a>{" "}
          and we’ll get back to you as soon as we can.
        </p>
        <div className="mt-20">
          <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:grid-cols-3 lg:gap-x-10">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  {faq.question}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {faq.answer}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
