// import { useState } from "react";
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Pricing from "./components/Pricing";
import YouTube from "./components/YouTube";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import Faqs from "./components/Faqs";
import Reviews from "./components/Reviews";
import { Routes, Route } from "react-router-dom";
import Contact from "./components/Contact";

export default function App() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      {/* NavBar */}
      <NavBar />
      <main className="isolate">
        <Hero />
        <Services />
        <Testimonials />
        <YouTube />
        <Faqs />
        <Contact />
        <Footer />
      </main>

      {/* <Routes>
        <Route
          path="/"
          element={<Hero />}
        />
        <Route
          path="/services"
          element={<Services />}
        />
        <Route
          path="/"
          element={<Hero />}
        />
        <Route
          path="/"
          element={<Hero />}
        />
        <Route
          path="/"
          element={<Hero />}
        />
      </Routes>
 */}
      {/* <main className="isolate">
          <Hero />
          <Services />
          <Testimonials />
          <YouTube />
          <Faqs />
        </main>

        <Footer /> */}
    </div>
  );
}
