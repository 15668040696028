import React from "react";
import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";
import { Link as LinkScroll } from "react-scroll";

import { CheckIcon } from "@heroicons/react/20/solid";

const includedFeatures = [
  "Pre-drive checks",
  "Basic cockpit drills",
  "Basic manoeuvring skills and reversing",
  "Dealing and coping with intersections and hazard detection",
  "Lane driving with traffic",
  "On and off ramps with higher speeds and motorway driving",
  "Emergency braking",
];

const tiers = [
  {
    name: "Our Price",
    id: "our-price",
    href: "#contact",
    priceHourly: "$80",
    description:
      "Lessons are 1 full hour. Debriefing and feedback is included in the 1hr lesson and it takes 5-10 minutes.",
    features: [
      "Restricted",
      "Full License",
      "Basic Skills",
      "Pre-test Assessment",
      "Overseas Conversions",
    ],
    featured: true,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Services = () => {
  return (
    <div
      className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32"
      id="about"
    >
      <div
        className="absolute -top-80 left-[max(6rem,33%)] -z-10 transform-gpu blur-3xl sm:left-1/2 md:top-20 lg:ml-20 xl:top-3 xl:ml-56"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              "polygon(63.1% 29.6%, 100% 17.2%, 76.7% 3.1%, 48.4% 0.1%, 44.6% 4.8%, 54.5% 25.4%, 59.8% 49.1%, 55.3% 57.9%, 44.5% 57.3%, 27.8% 48%, 35.1% 81.6%, 0% 97.8%, 39.3% 100%, 35.3% 81.5%, 97.2% 52.8%, 63.1% 29.6%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-300 sm:text-4xl">
            About us
          </h1>
          <p className="mt-6 text-xl leading-8 text-gray-300">
            At SM Driving, I have over 30 years of experience in the industry,
            and have helped countless students achieve their driving goals. I
            pride myself on providing a safe and welcoming environment for my
            students, and use only the latest teaching techniques and technology
            to ensure that you receive the best possible education. I am
            friendly, patient, and passionate about teaching, and am committed
            to making your learning experience as enjoyable and stress-free as
            possible.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:mt-10 lg:max-w-none lg:grid-cols-12">
          <div className="relative lg:order-last lg:col-span-5">
            {/* <svg
              className="absolute -top-[40rem] left-1 -z-10 h-[64rem] w-[175.5rem] -translate-x-1/2 stroke-gray-900/10 [mask-image:radial-gradient(64rem_64rem_at_111.5rem_0%,white,transparent)]"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e87443c8-56e4-4c20-9111-55b82fa704e3"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M0.5 0V200M200 0.5L0 0.499983" />
                </pattern>
              </defs>
              <rect
                width="100%"
                height="100%"
                strokeWidth={0}
                fill="url(#e87443c8-56e4-4c20-9111-55b82fa704e3)"
              />
            </svg> */}
            <figure className="border-l border-indigo-600 pl-8">
              {/* <blockquote className="text-xl font-semibold leading-8 tracking-tight text-gray-500">
                <p>
                  “Without my instructor's expert guidance just two days before
                  my full driving exam, I wouldn't have passed. Highly
                  professional and recommended to anyone seeking to learn to
                  drive confidently and safely.”
                </p>
              </blockquote>
              <figcaption className="mt-8 flex gap-x-4">
                <img
                  src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                  className="mt-1 h-10 w-10 flex-none rounded-full bg-gray-50"
                />
                <div className="text-sm leading-6">
                  <div className="font-semibold text-gray-400">Simonette</div>
                  <div className="text-gray-600">@Nurse</div>
                </div>
              </figcaption> */}
              <div className="mx-auto mt-16  max-w-lg  items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl ">
                {tiers.map((tier, tierIdx) => (
                  <div
                    key={tier.id}
                    className={classNames(
                      tier.featured
                        ? "relative bg-white shadow-2xl"
                        : "bg-white/60 sm:mx-8 lg:mx-0",
                      tier.featured
                        ? ""
                        : tierIdx === 0
                        ? "rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl"
                        : "sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none",
                      "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
                    )}
                  >
                    <h3
                      id={tier.id}
                      className="text-base font-semibold leading-7 text-indigo-600"
                    >
                      {tier.name}
                    </h3>
                    <p className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        {tier.priceHourly}
                      </span>
                      <span className="text-base text-gray-500">/per hour</span>
                    </p>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      {tier.description}
                    </p>
                    <ul
                      role="list"
                      className="mt-8 space-y-3 text-sm leading-6 text-gray-600 sm:mt-10"
                    >
                      {tier.features.map((feature) => (
                        <li
                          key={feature}
                          className="flex gap-x-3"
                        >
                          <CheckIcon
                            className="h-6 w-5 flex-none text-indigo-600"
                            aria-hidden="true"
                          />
                          {feature}
                        </li>
                      ))}
                    </ul>
                    {/* <a
                      href={tier.href}
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.featured
                          ? "bg-indigo-600 text-white shadow hover:bg-indigo-500"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                        "mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
                      )}
                    >
                      Contact us now
                    </a> */}
                    <LinkScroll
                      to="contact"
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.featured
                          ? "bg-indigo-600 text-white shadow hover:bg-indigo-500 cursor-pointer"
                          : "text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 cursor-pointer",
                        "mt-8 block rounded-md py-2.5 px-3.5 text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:mt-10"
                      )}
                      spy={true}
                      smooth={true}
                      offset={50}
                      duration={500}
                    >
                      Contact Us
                    </LinkScroll>
                  </div>
                ))}
              </div>
            </figure>
          </div>
          {/* SERViCES */}
          <div
            className="max-w-xl text-base leading-7 text-gray-400 lg:col-span-7"
            id="services"
          >
            <h2 className="mt-4 text-2xl font-bold tracking-tight text-gray-300">
              Our Services
            </h2>
            <p className="mt-6">
              We provide, basic driving skills for beginners and/or novice
              drivers who want to get their Restricted license and their Full
              license, driver assessment, mock driving test, overseas license
              conversion, pick up and drop off to all our students.
            </p>
            <p className="mt-6">
              SM Driving School believe in providing coaching and mentoring in
              its training to suit the needs of each individual not just to be a
              license holder but a safe and confident driver. Topics covered in
              our lessons include:
            </p>
            {/* <ul
              role="list"
              className="mt-8 max-w-xl space-y-8 text-gray-400"
            >
              <li className="flex gap-x-3">
                <CloudArrowUpIcon
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-300">
                    Service 1.
                  </strong>{" "}
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Maiores impedit perferendis suscipit eaque, iste dolor
                  cupiditate blanditiis ratione.
                </span>
              </li>
              <li className="flex gap-x-3">
                <LockClosedIcon
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-300">
                    Service 2.
                  </strong>{" "}
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure
                  qui lorem cupidatat commodo.
                </span>
              </li>
              <li className="flex gap-x-3">
                <ServerIcon
                  className="mt-1 h-5 w-5 flex-none text-indigo-600"
                  aria-hidden="true"
                />
                <span>
                  <strong className="font-semibold text-gray-300">
                    Service 3.
                  </strong>{" "}
                  Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
                  Et magna sit morbi lobortis.
                </span>
              </li>
            </ul> */}
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-300 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li
                  key={feature}
                  className="flex gap-x-3"
                >
                  <CheckIcon
                    className="h-6 w-5 flex-none text-indigo-600"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>

            <p className="mt-8">
              SM Driving School offers a practice test session where the
              instructor will show you what is to be expected for the
              Full/Restricted License Test. A one-hour training session is
              usually sufficient. The instructor will coach you, with
              commentary, through driving to a proficient standard, and if
              required tidy up your driving and advise you of any poor habits
              you may have developed. The instructor will discuss the sorts of
              questions the testing officer may ask and there are no set routes
              for this test.
            </p>
            <p className="mt-6">
              Our goal is not to make you someone who can drive or a licensed
              driver but to ensure that you become a safe confident driver.
            </p>
          </div>
          {/* SERVICES */}
        </div>
      </div>
    </div>
  );
};

export default Services;
