import React from "react";
import { Button } from "./Button";

import image from "../images/youtube.jpg";

function PlayIcon(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      aria-hidden="true"
      {...props}
    >
      <circle
        cx="12"
        cy="12"
        r="11.5"
        stroke="#D4D4D4"
      />
      <path
        d="M9.5 14.382V9.618a.5.5 0 0 1 .724-.447l4.764 2.382a.5.5 0 0 1 0 .894l-4.764 2.382a.5.5 0 0 1-.724-.447Z"
        fill="#A3A3A3"
        stroke="#A3A3A3"
      />
    </svg>
  );
}

const YouTube = () => {
  return (
    <div
      className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:px-8"
      id="youtube"
    >
      <img
        src={image}
        alt=""
        className="absolute inset-0 -z-10 h-full w-full object-cover"
      />
      <div
        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div
        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
        aria-hidden="true"
      >
        <div
          className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
          Youtube Channel
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-300">
          I believe that learning to drive should be accessible to everyone,
          which is why I have created a range of YouTube lessons that you can
          access for free. My YouTube lessons cover everything from basic
          driving skills to advanced techniques, and are designed to be easy to
          follow and understand. Subscribe to my channel today to access my
          library of lessons.
        </p>
        <div className="mt-8 flex justify-center">
          <Button
            href="https://www.youtube.com/@smdrivingschoolltd2377"
            variant="solid"
            color="gray"
          >
            <PlayIcon className="h-6 w-6 flex-none" />
            <span className="ml-2.5">Watch my Youtube videos</span>
          </Button>

          {/* <AppStoreLink color="white" /> */}
        </div>
      </div>
    </div>
  );
};

export default YouTube;
