import React from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";

import logo from "../images/logos/logo.png";

const Container = () => {
  return <div className={clsx("mx-auto max-w-7xl px-4 sm:px-6 lg:px-8")} />;
};

const NavLinksFooter = () => {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  return [
    ["About Us", "#about"],
    ["Pricing", "#services"],
    ["YouTube", "#youtube"],
    ["FAQs", "#faqs"],
  ].map(([label, href], index) => (
    <a
      key={label}
      href={href}
      className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-300 transition-colors delay-150 hover:text-gray-900 hover:delay-[0ms]"
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <AnimatePresence>
        {hoveredIndex === index && (
          <motion.span
            className="absolute inset-0 rounded-lg bg-gray-100"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0, delay: 0 },
            }}
          />
        )}
      </AnimatePresence>
      <span className="relative z-10">{label}</span>
    </a>
  ));
};

const Footer = () => {
  return (
    <footer className="border-t border-gray-200 bg-gray-900">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
          <div>
            <div className="flex items-center text-white">
              <img
                src={logo}
                className="h-20 w-auto"
                alt=""
              />

              <div className="ml-4">
                <p className="text-base font-semibold text-gray-300">
                  SM Driving School Ltd.
                </p>
                <p className="mt-1 text-sm">Where safe motoring begins.</p>
              </div>
            </div>
            <nav className="mt-11 flex gap-8">
              <NavLinksFooter />
            </nav>
          </div>
        </div>
        <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <p className="mt-6 text-sm text-gray-300 md:mt-0">
            Website designed {new Date().getFullYear()} and maintained by
            Kenneth Sta Maria
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
